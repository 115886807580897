<template>
    <div class="mypage body index">
        <div class="inner-box my-cell-list">
            <van-tabs type="card" color="#1989fa" animated>
               <van-tab title="参与任务">
                   <div class="">
                       <van-cell-group>
                           <van-cell value-class="flex-align-end"
                            :key="task.id"
                            v-for="task in taskList"
                            label-class="cell-label van-multi-ellipsis--l2"
                            title-class="cell-title"
                            >
                               <template slot="title">
                                <div class="flex-align">
                                   <span class="font-size3 bold">{{`编码${task.task_no}`}}</span>
                                </div>
                               </template>
                               <template slot="label">
                                 <span class="font-size0 van-color-text-assist">{{task.title}}</span>
                               </template>
                               <template>
                                 <span class="font-size2 warn-status">{{task.statusText}}</span>
                               </template>
                             </van-cell>
                       </van-cell-group>
                   </div>
               </van-tab>
               <van-tab title="工时记录">
                   <van-list
                     v-model="loading"
                     :finished="finished"
                     finished-text="没有更多了"
                     error-text="请求失败，点击重新加载"
                     @load="getPageSection"
                   >
                     <van-cell-group>
                         <van-cell value-class="flex-align-end"
                          v-for="section in sectionList"
                          :key="section.id"
                          label-class="cell-label cell-label2 van-multi-ellipsis--l2"
                          title-class="cell-title cell-title2"
                          >
                             <template slot="title">
                              <div class="flex-align">
                                 <span class="font-size3 bold">{{getTaskSectionLabel(section)}}</span>
                              </div>
                             </template>
                             <template slot="label">
                               <span class="font-size0 van-color-text-assist">{{getTaskSectionTitle(section)}}</span>
                             </template>
                           </van-cell>
                     </van-cell-group>
                   </van-list>
               </van-tab>
             </van-tabs>
        </div>
    </div>
</template>

<script>
    import { getMesMyJoinTaskList, getMesMyUserSectionList } from '@/api/task.js';
    import { checkLogin } from '@/utils/common.js';
    export default{
        data() {
            return{
                taskList: [],
                sectionList: [],
                error: false,
                loading: false,
                finished: false,
                page: 1,
                limit: 15,
                count: 0,
            }
        },
        activated() {
            this.getPage();
        },
        methods:{
            getPage(){
               checkLogin().then(()=>{
                   // Promise.all([ getMesMyJoinTaskList()]).then((ret){
                   //      console.log(ret,getMesMyTaskUser, getMesMyUserSectionList);
                   //  })
                   getMesMyJoinTaskList().then((ret)=> {
                       this.taskList = ret.data;
                   });
               })
            },
            getPageSection(){
                var params = {
                    page: this.page,
                    limit: this.limit,
                };
                getMesMyUserSectionList(params).then((ret)=> {
                    console.log('3', ret.data);
                    this.loading = false;
                    this.sectionList = this.sectionList.concat(ret.data.list);
                    this.count = ret.data.count;
                    if(this.count == this.page){
                        this.finished = true;
                    }else{
                        this.page = this.page + 1;
                    }
                });
            },
            getTaskStatus(task){
                //return task.end_reason;
                if(task.status == 3){
                    return '已完成';
                }else if(task.status == 0){
                    return '未开始';
                }else{
                    if(!task.last){
                        return '未开始';
                    }
                    if(task.last.end_reason){
                        if(task.last.end_reason == '离开'){
                            return '已离开';
                        }
                         return '已暂停';
                    }else{
                        return '进行中';
                    }
                }
            },
            getTaskSectionLabel(section){
                return `${section.start_time} 至 ${section.end_time || '-'}`;
                //return `${section.start_time} 至 ${section.end_time}`;
            },
            getTaskSectionTitle(section){
                var extr = this.getDiffTimes(section.start_time, section.end_time);
                return extr;
            },
            getDiffTimes(start, end){
                if(!end || end == '无'){
                    return '';
                }
                var diff =  new Date(end) - new Date(start);
                
                var totalMins = diff / (60 * 1000);
                var mins = Math.floor(totalMins % 60);
                var hours = Math.floor(totalMins / 60);
                var times = hours?`${hours}小时${mins}分钟`:`${mins}分钟`;
                return '合计' + times;
            },
        },
    }
</script>

<style scoped>
    .mypage{
        min-height: 100vh;
        background-color: #fff;
    }
    .inner-box{
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .custom-label{
        width: 100%;
    }
    .my-cell-list .cell-class{
        margin: 20px 0;
    }
    .my-cell-list .cell-title{
        font-size: 14px;
        color: #333;
        text-align: left;
        font-weight: bold;
        flex: 4 !important;
    }
    .my-cell-list .cell-label{
        font-size: 14px;
        color: #666;
        /* font-weight: bold; */
    }
    .my-cell-list .cell-title.cell-title2{
        /* font-weight: normal; */
        color: #666;
    }
    .my-cell-list .cell-label.cell-label2{
        font-weight: bold;
        color: #333;
    }
    .my-cell-list  .warn-status{
        display: block;
        align-items: center;
        color: #ff7b35;
        width: 100%;
        height: 100%;
        margin-top: 10px;
    }
</style>